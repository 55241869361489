<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="fullscreen"
        :transition="transition"
        max-width="600"
        persistent
        scrollable
    >
        <template v-slot:activator="{ on: onDialog, attrs: attrsDialog }">
            <v-list-item
                v-if="latestMessage"
                v-bind="attrsDialog"
                v-on="onDialog"
            >
                <v-list-item-content>
                    <v-list-item-title style="position: relative">
                        <small class="grey--text">{{ typologyDesc }}</small><br>
                        <strong class="grey--text text--darken-3">{{ customer }}</strong>

                        <div
                            class="grey--text text--darken-2"
                            style="position: absolute; top: 5px; right: 0; font-size: 0.7em"
                        >
                            {{ latestMessage.datetime | dateTo_numericFull }}
                        </div>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ latestMessage.operator || latestMessage.customer }}:

                        <span v-if="latestMessage.text">
                            {{ latestMessage.text }}
                        </span>

                        <v-icon
                            v-else-if="latestMessage.attachments"
                            color="green"
                            small
                        >
                            {{ 'mdiFileDocument' | mdi }}
                        </v-icon>

                        <v-icon
                            v-else-if="latestMessage.audio"
                            color="green"
                            small
                        >
                            {{ 'mdiMicrophone' | mdi }}
                        </v-icon>

                        <v-icon
                            v-else-if="latestMessage.images"
                            color="green"
                            small
                        >
                            {{ 'mdiCamera' | mdi }}
                        </v-icon>
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-menu
                        :close-on-content-click="false"
                        bottom
                        offset-y
                        origin="top right"
                        right
                        transition="scale-transition"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>{{ 'mdiDotsVertical' | mdi }}</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item
                                v-if="!chat.archived"
                                @click="onArchive()"
                            >
                                <v-list-item-avatar size="32">
                                    <v-icon>{{ 'mdiArchiveArrowDown' | mdi }}</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        Archivia chat
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-else
                                @click="onExtract()"
                            >
                                <v-list-item-avatar size="32">
                                    <v-icon>{{ 'mdiArchiveArrowUp' | mdi }}</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        Estrai dall'archivio
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <template v-if="administrator">
                                <v-divider/>

                                <v-subheader class="text-uppercase font-weight-bold">
                                    {{ chat.assignment ? 'Riassegna' : 'Assegna' }} a
                                </v-subheader>

                                <v-list-item
                                    v-for="({id, name}) in operatorsFiltered"
                                    :key="id"
                                    @click="onAssign(id)"
                                >
                                    <v-list-item-avatar size="32">
                                        <v-icon>{{ 'mdiAccountArrowLeft' | mdi }}</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <template v-if="chat.archived">
                                    <v-divider/>

                                    <v-menu
                                        close-on-content-click
                                        bottom
                                        dark
                                        left
                                        min-width="200"
                                        offset-y
                                        origin="top left"
                                        transition="scale-transition"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-list-item
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon>{{ 'mdiDelete' | mdi }}</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Elimina chat</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>

                                        <v-list color="red">
                                            <v-list-item @click="onDelete()">
                                                <v-list-item-icon>
                                                    <v-icon>{{ 'mdiDelete' | mdi }}</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Conferma eliminazione</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </template>
                        </v-list>
                    </v-menu>
                </v-list-item-action>
            </v-list-item>
        </template>

        <v-card>
            <v-toolbar
                class="flex-grow-0"
                color="secondary"
                dark
            >
                <v-btn
                    icon
                    @click="onClose()"
                >
                    <v-icon>{{ 'mdiClose' | mdi }}</v-icon>
                </v-btn>

                <v-toolbar-title
                    class="subtitle-1 pl-5"
                    style="line-height: 1"
                >
                    <div><small>{{ typologyDesc }}</small></div>
                    <div class="font-weight-bold">{{ customer }}</div>
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text
                v-if="dialog"
                :ref="el => chatContainer = el"
                class="overflow-y-auto d-flex flex-column align-end px-3 pt-3 pb-0 chat"
            >
                <v-sheet
                    v-for="(message, idx) in messages"
                    :key="`V-SHEET-${idx}`"
                    :class="{
                        'align-self-start': ('customer' in message),
                        'align-self-end': ('operator' in message),
                        'ml-8': ('operator' in message),
                        'mr-8': ('customer' in message),
                        received: ('customer' in message),
                        sent: ('operator' in message),
                    }"
                    :color="('customer' in message) ? colors.blueGrey.darken4 : colors.teal.darken4"
                    :max-width="message.audio ? null : '90%'"
                    class="px-3 py-2 mb-3"
                    dark
                    elevation="2"
                    min-width="180"
                >
                    <div
                        :style="{
                            'border-left-color': ('customer' in message) ? colors.blueGrey.darken4 : colors.teal.darken4,
                            'border-right-color': ('customer' in message) ? colors.blueGrey.darken4 : colors.teal.darken4,
                        }"
                        class="corner"
                    />

                    <div
                        class="text-no-wrap blue-grey--text text-uppercase"
                    >
                        <small>{{ message.customer || message.operator }}</small>
                    </div>

                    <div
                        v-if="message.text"
                        v-html="message.text"
                    />

                    <audio
                        class="audio mt-1"
                        v-if="message.audio"
                        :src="cdn(message.audio, 'audio')"
                        controls
                        preload="metadata"
                    />

                    <template v-if="message.attachments">
                        <v-chip
                            v-for="attach of message.attachments"
                            :key="attach.name"
                            class="mt-2"
                            color="green darken-2"
                            label
                            @click="onDownload(attach.name, 'attachments')"
                        >
                            {{ attach.name }}
                        </v-chip>
                    </template>

                    <template v-if="message.images">
                        <v-row
                            class="mt-1"
                            dense
                        >
                            <v-col
                                v-for="image in message.images"
                                :key="image"
                                :cols="message.images.length > 1 ? 6 : 12"
                            >
                                <v-dialog
                                    class="pa-0 elevation-0"
                                    content-class="pa-0 elevation-0 ma-1 rounded-0"
                                    width="100%"
                                >
                                    <template v-slot:activator="{ attrs: attrsDialog, on: onDialog }">
                                        <v-img
                                            :aspect-ratio="1"
                                            :lazy-src="cdn(image, 'photo')"
                                            :src="cdn(image, 'photo')"
                                            :width="message.images.length > 1 ? 150 : 300"
                                            v-bind="attrsDialog"
                                            v-on="onDialog"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row
                                                    align="center"
                                                    class="fill-height ma-0"
                                                    justify="center"
                                                >
                                                    <v-progress-circular
                                                        color="grey lighten-5"
                                                        indeterminate
                                                    />
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </template>

                                    <v-img
                                        :lazy-src="cdn(image, 'photo')"
                                        :src="cdn(image, 'photo')"
                                        contain
                                        width="100%"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                align="center"
                                                class="fill-height ma-0"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    color="grey lighten-5"
                                                    indeterminate
                                                />
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </template>

                    <time
                        :datetime="message.datetime"
                        class="d-block text-right"
                    >
                        {{ message.datetime | dateTo_numericFull }}
                    </time>
                </v-sheet>
            </v-card-text>

            <v-card-actions class="pa-0">
                <v-slide-x-reverse-transition hide-on-leave>
                    <std-toolbar
                        v-if="textToolbar"
                        key="STD-TOOLBAR"
                        v-model.trim="chatMessage"
                        :btn-send-text-showed="!!chatMessage"
                        :sending-audio="sendingAudio"
                        :sending-file="sendingFile"
                        :sending-text="sendingText"
                        :text-disabled="sendingAudio || sendingFile || sendingText"
                        @send-text="onMessageSend"
                        @audio-message="onAudioMessage"
                        @send-file="onFileMessage($event)"
                    />

                </v-slide-x-reverse-transition>
                <v-slide-x-reverse-transition hide-on-leave>
                    <audio-toolbar
                        v-if="audioToolbar"
                        key="AUDIO-TOOLBAR"
                        @abort="onAudioAbort()"
                        @send="onAudioSend($event)"
                    />
                </v-slide-x-reverse-transition>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { settings } from "../../libraries/storage";
import { assign, filter, map, maxBy } from "lodash";
import { axiosIot } from "../../libraries/axios";
import { colors } from "vuetify/lib";
import AudioToolbar from "./components/AudioToolbar.vue";
import StdToolbar from "./components/StdToolbar.vue";
import { DateTime } from "luxon";
import { downloadFromUrl, fromInputFileToBase64 } from "../../libraries/filesystem";

export default {
    components: {
        AudioToolbar,
        StdToolbar,
    },

    computed: {
        account() {
            return settings.get('user')
        },

        accountId() {
            return this.account.id || null
        },

        colors() {
            return colors
        },

        fullscreen() {
            return (this.$vuetify.breakpoint.width < 600)
        },

        latestMessage() {
            if (this.chat) {
                return maxBy(this.chat.chat, 'datetime')
            }

            return undefined
        },

        messages() {
            return this.chat?.chat
        },

        operatorsFiltered() {
            return filter(this.operators, ({id}) => id !== this.chat.assignment)
        },

        transition() {
            return this.fullscreen ? 'dialog-bottom-transition' : 'dialog-transition'
        },

        typologyDesc() {
            if (this.chat?.typology === 0) {
                return "Assistenza amministrativa"
            } else if (this.chat?.typology === 1) {
                return "Assistenza commerciale"
            } else {
                return "Assistenza tecnica"
            }
        },
    },

    data() {
        return {
            audioToolbar: false,
            chatContainer: null,
            chatInput: false,
            chatMessage: null,
            dialog: false,
            onOpenScroll: true,
            sendingAudio: false,
            sendingFile: false,
            sendingText: false,
            textToolbar: true,
        }
    },

    filters: {
        colorFromLetter(content) {
            const hash = (str => {
                let hash = 0

                for (let i = 0, ii = str.length; i < ii; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash)
                }

                return hash
            })(content)

            const c = (hash & 0x00FFFFFF).toString(16)

            return '#' + '00000'.substring(0, 6 - c.length) + c
        },
        dateTo_numericFull(isoDate) {
            return isoDate ? DateTime.fromISO(isoDate).toFormat('dd/LL/yyyy HH:mm') : ""
        },
    },

    methods: {
        cdn(file, context) {
            return `${process.env.VUE_APP_API_IOT}/v1/chatbot/${this.chat?.uuid}/cdn/${context}/${file}`
        },

        async onArchive() {
            await axiosIot.patch(`/v1/chatbot/${this.chat.uuid}`, {archive: true})

            this.onClose()
        },

        async onAssign(operator) {
            await axiosIot.patch(`/v1/chatbot/${this.chat.uuid}`, {assign: operator})

            this.onClose()
        },

        onAudioAbort() {
            this.audioToolbar = false
            this.textToolbar = true
        },

        onAudioMessage() {
            this.audioToolbar = true
            this.textToolbar = false
        },

        async onAudioSend(blob) {
            const audio = await fromInputFileToBase64(blob)

            if (audio) {
                this.sendingAudio = true

                await this.onMessageSend({audio})
            }

            this.onAudioAbort()
        },

        onClose() {
            this.dialog = false

            this.$emit('close')
        },

        async onDelete() {
            await axiosIot.delete(`/v1/chatbot/${this.chat.uuid}`)

            this.onClose()
        },

        async onDownload(file, context) {
            await downloadFromUrl(this.cdn(file, context))
        },

        async onExtract() {
            await axiosIot.patch(`/v1/chatbot/${this.chat.uuid}`, {extract: true})

            this.onClose()
        },

        async onFileMessage(payload) {
            const [
                context,
                files,
            ] = payload

            let data = {}

            if (context === "attachs") {
                data.attachments = await Promise.all(
                    map(files, async file => {
                        return {
                            data: await fromInputFileToBase64(file),
                            name: file.name,
                        }
                    }),
                )
            } else if (context === "image") {
                data.photo = await Promise.all(
                    map(files, file => fromInputFileToBase64(file)),
                )
            }

            await this.onMessageSend(data)
        },

        async onMessageSend(data) {
            this.sendingText = true

            const uuid = this.chat.uuid

            if (this.chat.archived) {
                this.$emit('extract', uuid)
            }

            await axiosIot.put(`/v1/chatbot/${uuid}`, assign(data, {
                operator: this.accountId,
                text: this.chatMessage,
            }))

            this.chatMessage = null

            this.sendingAudio = false
            this.sendingFile = false
            this.sendingText = false
        },

        onOpen() {
            this.$emit('open')
        },

        onScrollDown() {
            this.$nextTick(() => {
                if (this.chatContainer) {
                    const options = {
                        left: 0,
                        top: this.chatContainer.scrollHeight,
                    }

                    if (!this.onOpenScroll) {
                        options.behavior = "smooth"
                    } else {
                        this.onOpenScroll = false
                    }

                    this.chatContainer.scrollTo(options)
                }
            })
        },
    },

    name: "ChatbotItem",

    props: {
        administrator: {
            required: true,
            type: Boolean,
        },
        chat: {
            default: null,
            type: Object,
        },
        customer: {
            required: true,
            type: String,
        },
        operators: {
            default: null,
            type: Array,
        },
    },

    updated() {
        if (this.dialog) {
            this.$nextTick(() => {
                this.onScrollDown()
            })
        }
    },

    watch: {
        async dialog(dialog) {
            if (dialog) {
                this.$store.commit('setThemeColor', colors.red.darken1)
                this.chatInput = true
                this.onOpen()
            } else {
                this.$store.commit('setThemeColor', null)
                this.chatInput = false
                this.onOpenScroll = true
            }
        },
    },
}
</script>

<style lang="scss">
.chat {
    width: 100%;

    .received,
    .sent {
        font-size: 1.1em;
        max-width: 90%;
        min-width: 120px;
        position: relative;

        .corner {
            border-top: 0 solid transparent;
            border-bottom: 10px solid transparent;
            height: 0;
            position: absolute;
            top: 0;
            width: 0;
        }

        time {
            color: rgba(255, 255, 255, .75);
            font-size: 0.8em;
            margin-top: 2px
        }
    }

    .received {
        border-radius: 0 5px 5px 5px;

        .corner {
            left: -9px;
            border-right-style: solid;
            border-right-width: 10px;
        }
    }

    .sent {
        border-radius: 5px 0 5px 5px;

        .corner {
            border-left-style: solid;
            border-left-width: 10px;
            right: -9px;
        }
    }

    audio.audio {
        box-sizing: border-box;
        display: block;
        margin-bottom: 5px;
        width: 300px;
    }
}
</style>
