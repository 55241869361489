<template>
    <v-toolbar
        color="secondary"
        elevation="0"
    >
        <v-btn
            icon
            @click="onAbort()"
        >
            <v-icon color="red lighten-4">
                {{ 'mdiDelete' | mdi }}
            </v-icon>
        </v-btn>

        <v-spacer/>

        <v-btn
            :loading="btnSendAudioLoading"
            icon
            @click="onStop()"
        >
            <v-icon color="red lighten-4">{{ 'mdiSend' | mdi }}</v-icon>
        </v-btn>
    </v-toolbar>
</template>

<script>
import { reduce } from "lodash";

export default {
    async beforeDestroy() {
        if (this.mediaRecorder) {
            this.mediaRecorder = null
        }
    },

    computed: {
        mimeType() {
            return reduce([
                "audio/ogg",
                "audio/mp3",
                "audio/webm",
                "audio/wav",
            ], (format, mimeType) => {
                if (!format && MediaRecorder.isTypeSupported(mimeType)) {
                    format = mimeType
                }

                return format
            }, null)
        },
    },

    async created() {
        await this.recording()
    },

    data() {
        return {
            mediaRecorder: null,
        }
    },

    methods: {
        onAbort() {
            this.mediaRecorder.stop()
            this.$emit('abort')
        },

        onSend(payload) {
            this.$emit('send', payload)
        },

        async onStop() {
            const getBlob = () => {
                return new Promise(resolve => {
                    this.mediaRecorder.addEventListener('dataavailable', (e) => {
                        if (e.data.size > 0) {
                            resolve(e.data)
                        } else {
                            resolve()
                        }
                    })
                })
            }

            this.mediaRecorder.stop()

            const blob = await getBlob()

            if (blob) {
                this.onSend(blob)
            } else {
                this.onAbort()
            }
        },

        async recording() {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                const mediaStream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        autoGainControl: true,
                        channelCount: 2,
                        echoCancellation: true,
                        noiseSuppression: true,
                        sampleRate: 44100,
                    },
                    video: false,
                })

                if (mediaStream) {
                    const mediaRecorder = new MediaRecorder(mediaStream, {
                        mimeType: this.mimeType,
                    })

                    if (mediaRecorder) {
                        mediaRecorder.addEventListener('stop', () => {
                            mediaStream.getTracks().forEach(track => track.stop())
                        })

                        mediaRecorder.start()

                        this.mediaRecorder = mediaRecorder
                    }
                }
            }
        },
    },

    name: "AudioToolbar",

    props: {
        btnSendAudioLoading: {
            default: false,
            type: Boolean,
        },
    },
}
</script>

<style scoped>

</style>
