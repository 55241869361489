<template>
    <v-container
        class="mx-auto pa-0 fill-height align-start align-content-start"
        fluid
    >
        <v-expand-transition>
            <v-btn
                v-if="!archive"
                block
                outlined
                text
                tile
                x-large
                @click="archive = true"
            >
                <v-icon
                    color="grey"
                    left
                >
                    {{ 'mdiPackageDown' | mdi }}
                </v-icon>

                <span
                    style="text-transform: none"
                    class="grey--text"
                >
                    Conversazioni archiviate
                </span>
            </v-btn>
        </v-expand-transition>

        <v-list
            v-if="(chatList.length > 0) && !forceRendering"
            subheader
            width="100%"
        >
            <template v-for="(chat, index) in chatList">
                <v-divider
                    v-if="index > 0"
                    :key="`V-DIVIDER-${index}`"
                />
                <chatbot-item
                    :key="`CHATBOT-ITEM-${index}`"
                    :administrator="administrator"
                    :chat="tickets[ chat.uuid ]"
                    :customer="chat.customer"
                    :operators="operators"
                    @close="enableForceRendering = true"
                    @extract="onChatRoomExtracted"
                    @open="enableForceRendering = false"
                />
            </template>

        </v-list>
        <v-sheet
            v-else
            width="100%"
        >
            <v-card-text class="text-center">
                <v-icon
                    color="grey lighten-2"
                    dark
                    size="200"
                >
                    {{ 'mdiChatProcessingOutline' | mdi }}
                </v-icon>
                <p class="pa-3 grey--text text--lighten-1">
                    <strong>Non hai richieste di assistenza</strong>
                </p>
            </v-card-text>
        </v-sheet>

        <portal
            v-if="archive"
            to="navigation-back"
        >
            <v-btn
                @click="archive = null"
                icon
            >
                <v-icon>{{ 'mdiArrowLeft' | mdi }}</v-icon>
            </v-btn>
        </portal>
    </v-container>
</template>

<script>
import { settings } from "../libraries/storage";
import { firebaseApp } from "../libraries/firebase";
import {
    equalTo,
    getDatabase,
    onChildAdded,
    onChildRemoved,
    orderByChild,
    onValue,
    query,
    ref,
} from "firebase/database";
import { axiosWs } from "../libraries/axios";
import { includes, keys, map, maxBy, orderBy, values } from "lodash";
import ChatbotItem from "./Chatbot/ChatbotItem.vue";
import { Portal } from "portal-vue";
import { deepClone } from "../libraries/object";
import { captureException } from "../libraries/bugsnag";

/**
 * @var Unsubscribe
 */
let offChildAdded = null

/**
 * @var Unsubscribe
 */
let offChildRemoved = null

/**
 * @var Unsubscribe
 */
let offValue = null

export default {
    beforeDestroy() {
        if (offValue) {
            offValue()

            offValue = null
        }

        if (offChildAdded) {
            offChildAdded()

            offChildAdded = null
        }

        if (offChildRemoved) {
            offChildRemoved()

            offChildRemoved = null
        }

        this.database = null
    },

    components: {
        ChatbotItem,
        Portal,
    },

    computed: {
        account() {
            return settings.get('user')
        },

        accountId() {
            return this.account?.id || null
        },

        administrator() {
            return includes([1, 2], this.account.role)
        },

        isTechnician() {
            return (this.account.role === 8)
        },

        latestMessage() {
            if (this.chatList.length > 0) {
                return this.chatList[ 0 ]
            }

            return undefined
        },
    },

    async created() {
        this.$store.commit('setToolbarTitle', 'Assistenza')

        if (this.administrator) {
            await this.getOperators()
        }

        this.onDatabase()
    },

    data() {
        return {
            archive: null,
            chatList: [],
            customerCache: {},
            database: null,
            dialog: false,
            enableForceRendering: false,
            forceRendering: false,
            loading: false,
            operators: [],
            tickets: null,
        }
    },

    methods: {
        async getOperators() {
            try {
                this.operators = []

                const response = await axiosWs.get('/v1/it/employees', {
                    params: {
                        filterRoles: [
                            1,  // SuperUser
                            2,  // Administrator
                            8,  // Technician
                        ],
                    },
                })

                if (response.status === 200 && response.data.code === 302) {
                    const {recordset} = response.data.data.payload
                    const operators = map(recordset, ({cognome, nome, user}) => {
                        let name = `${cognome}, ${nome}`.toUpperCase()

                        if (user.role === 1) {
                            name += ' (SuperUser)'
                        } else if (user.role === 2) {
                            name += ' (Amministratore)'
                        } else if (user.role === 8) {
                            name += ' (Tecnico)'
                        }

                        return {
                            id: user.id,
                            name,
                        }
                    })

                    this.operators = orderBy(operators, ['name'], ['asc'])
                }
            } catch (e) {
                captureException(e)

                alert(e.message)
            }
        },

        onChatRoomExtracted() {
            this.archive = null
        },

        onDatabase() {
            if (!this.database) {
                const db = getDatabase(firebaseApp)
                this.database = ref(db, '/')
            }


            this.chatList = []
            this.tickets = null

            if (this.database) {
                if (!offChildAdded) {
                    offChildAdded = onChildAdded(this.database, () => this.onDatabaseBoot())
                }

                if (!offChildRemoved) {
                    offChildRemoved = onChildRemoved(this.database, () => this.onDatabaseBoot())
                }
            }
        },

        async onDatabaseBoot() {
            if (this.database && !offValue && !this.loading) {
                const filter = query(this.database, orderByChild('archived'), equalTo(this.archive))
                offValue = onValue(filter, async snapshot => {
                    this.loading = true

                    const recordset = snapshot.val()
                    const tickets = {}

                    if (recordset) {
                        for (const ticket of values(recordset)) {
                            if (this.isTechnician && (
                                (ticket.typology !== 2) ||
                                (ticket.assignment && ticket.assignment !== this.accountId)
                            )) {
                                continue
                            }

                            tickets[ ticket.uuid ] = ticket
                        }
                    }

                    this.tickets = (keys(tickets).length > 0) ? deepClone(tickets) : null

                    if (keys(tickets).length > 0) {
                        this.chatList = orderBy(await Promise.all(
                            map(values(tickets), async ticket => {
                                const latest = maxBy(ticket.chat, 'datetime')

                                return {
                                    customer: ticket.chat[ 0 ].customer,
                                    datetime: latest.datetime,
                                    latest,
                                    typology: ticket.typology,
                                    uuid: ticket.uuid,
                                }
                            }),
                        ), 'datetime', 'desc')
                    } else {
                        this.chatList = []
                    }

                    this.loading = false
                })
            }
        },
    },

    name: "Chatbot",

    watch: {
        archive(archive) {
            this.$store.commit('setToolbarTitle', !archive ? 'Assistenza' : 'Archivio conversazioni')

            if (offValue) {
                offValue()

                offValue = null
            }

            this.chatList = []

            this.onDatabaseBoot()
        },

        latestMessage(latestMessage) {
            if (latestMessage) {
                const latest = settings.get('chatbot', 'latest', latestMessage.uuid)

                if (latest !== latestMessage.latest.datetime) {
                    settings.set('chatbot', 'latest', latestMessage.uuid, latestMessage.latest.datetime)

                    if (!this.dialog) {
                        this.showTooltip = true
                    }
                }
            }
        },

        showTooltip(showTooltip) {
            if (showTooltip) {
                this.$nextTick(() => {
                    setTimeout(() => this.showTooltip = false, 5000)
                })
            }
        },
    },
}
</script>

<style scoped>

</style>
