<template>
    <v-toolbar
        color="secondary"
        flat
    >
        <v-menu
            close-on-content-click
            content-class="elevation-0 pa-0 fab"
            nudge-top="5"
            offset-y
            origin="bottom left"
            top
            transition="scroll-y-reverse-transition"
        >
            <template v-slot:activator="{ attrs: attrsMenu, on: onMenu }">
                <v-btn
                    :loading="sendingFile"
                    icon
                    v-bind="attrsMenu"
                    v-on="onMenu"
                >
                    <v-icon color="red lighten-4">{{ 'mdiPaperclip' | mdi }}</v-icon>
                </v-btn>
            </template>

            <div class="mb-2">
                <v-btn
                    color="green darken-3"
                    dark
                    elevation="0"
                    fab
                    height="48"
                    width="48"
                    @click="onInputClick('attachs')"
                >
                    <v-icon>{{ 'mdiFileDocument' | mdi }}</v-icon>
                </v-btn>
            </div>
            <div class="mb-1">
                <v-btn
                    color="purple"
                    dark
                    elevation="0"
                    fab
                    height="48"
                    width="48"
                    @click="onInputClick('image')"
                >
                    <v-icon>{{ 'mdiImageArea' | mdi }}</v-icon>
                </v-btn>
            </div>
        </v-menu>

        <div style="width: 100%">
            <v-text-field
                v-model.trim="text"
                :disabled="textDisabled"
                background-color="red lighten-4"
                clearable
                dense
                hide-details
                outlined
                placeholder="Scrivi un messaggio"
                @keyup.enter="onSendText"
            />
        </div>

        <v-btn
            v-if="btnSendTextShowed || !btnAudio"
            :loading="sendingText"
            icon
            @click="onSendText"
        >
            <v-icon color="red lighten-4">{{ 'mdiSend' | mdi }}</v-icon>
        </v-btn>
        <v-btn
            v-else-if="btnAudio"
            :loading="sendingAudio"
            icon
            @click="onAudioMessage"
        >
            <v-icon color="red lighten-4">
                {{ 'mdiMicrophone' | mdi }}
            </v-icon>
        </v-btn>

        <input
            v-show="false"
            ref="inputFile"
            multiple
            type="file"
            @change="onInputFile"
        >
    </v-toolbar>
</template>

<script>
import { map } from "lodash";

export default {
    computed: {
        inputFile: {
            cache: false,
            get() {
                return this.$refs.inputFile
            },
        },

        text: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },

        btnAudio() {
            return (navigator.mediaDevices && ("getUserMedia" in navigator.mediaDevices))
        }
    },

    methods: {
        onAudioMessage() {
            this.$emit('audio-message')
        },

        onInputClick(context) {
            if (context === "attachs") {
                this.inputFile.setAttribute('accept', 'application/pdf')
            } else if (context === "image") {
                this.inputFile.setAttribute('accept', 'image/jpeg')
            } else if (context === "video") {
                this.inputFile.setAttribute('accept', 'video/mpeg, video/webm, video/mp4')
            }

            this.context = context
            this.inputFile.click()
        },

        async onInputFile(event) {
            const input = event.target

            if (input.files && (input.files.length > 0)) {
                this.onSendFile(map(input.files, file => file))

                this.context = null
            }
        },

        onSendFile(payload) {
            this.$emit('send-file', [
                this.context,
                payload,
            ])
        },

        onSendText() {
            this.$emit('send-text')
        },
    },

    data() {
        return {
            context: null,
        }
    },

    name: "StdToolbar",

    props: {
        btnSendTextShowed: {
            default: true,
            type: Boolean,
        },

        sendingAudio: {
            default: false,
            type: Boolean,
        },

        sendingFile: {
            default: false,
            type: Boolean,
        },

        sendingText: {
            default: false,
            type: Boolean,
        },

        textDisabled: {
            default: false,
            type: Boolean,
        },

        value: {
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
.fab {
    left: 4px !important;
}
</style>
